import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersJobPosting = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Design Director</h1>
      <div className="jobdescription col-md-12"> 
      Most companies make products that people buy—we want to make products that people fall in love with. Magic happens when the lines between branding, interface, packaging and advertising start to blur.
        </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Oversee the visual design and production of our digital product.
            </li>
            <li>
              Grow and manage a team of visual designers and provide mentorship
              in all areas of production including visual, copy and experience
              design.
            </li>
            <li>
              Continue evolving Uno’s brand identity in the digital space and
              ensure it is cohesive.
            </li>
            <li>
              Work with product managers, developers and UX designers to enhance
              the physical product with a rich digital experience.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>A designer who believes form and function are inseparable.</li>
            <li>Equally systematic and story-driven.</li>
            <li>Highly skilled in Adobe software and Sketch.</li>
            <li>
              Always looking at the big picture and at the same time making it
              pixel-perfect.
            </li>
            <li>Not afraid to get your hands dirty.</li>
            <li>A clear communicator—verbally and visually. </li>
            <li>Equally concerned with concepts and craft. </li>
            <li>
              Able to provide meaningful feedback that pushes the quality of
              work.
            </li>
            <li>Empowered to make decisions.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              7+ years of experience working on digital products and web
              experiences in-house or at an agency.
            </li>
            <li>
              Portfolio showcasing high profile brands and projects including
              web applications, mobile apps, ecommerce site, CRM and branded
              digital experiences.
            </li>
            <li>
              Experience leading a team of visual designers and collaborating
              with UX designers.
            </li>
            <li>
              Intuition for what works and ability to communicate it internally.
            </li>
            <li>
              Ability to distill and organize information to create clarity in a
              visually appealing manner.{" "}
            </li>
            <li>
              Fluency in both design and technical development terminology as
              well as the ability to translate between the two.{" "}
            </li>
            <li>
              Best-in-class practices pertaining to systematic design such as
              grids, components, responsive layouts, etc.{" "}
            </li>
            <li>
              Deep understanding of how to bridge the gap between brand design
              and interaction design.
            </li>
            <li>Previous experience with user testing and</li>
            <li>
              Efficient visual development skills and ability to quickly
              iterate.{" "}
            </li>
            <li>
              Demonstrated aptitude in establishing and managing a design
              pipeline that feeds into development.
            </li>
            <li>
              Ability to scope against product roadmap and distribute workload
              amongst design team.
            </li>
            <li>Experience working on physical-digital product is a plus.</li>
            <li>Experience working on brand campaigns is a plus. </li>
            <li>Experience working in startup environment is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersJobPosting;
